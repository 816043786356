import { createApp } from 'vue';
import { createLogger } from 'vue-logger-plugin';
import { DeviceService } from './services/device.service';
import { GTMService } from './services/gtm.service';
import { FetchService } from './services/fetch.service';
import { LocationService } from './services/location.service';
import { NewslettersService } from './services/newsletters.service';
import { NewsletterManageService } from './services/newslettermanage.service';
import { UtilsService } from './services/utils.service';
import { createPinia } from 'pinia';
import { components } from './components';
import { ComponentDefinition } from './common/models/app/component-definition.model';
import { useIdentityStore } from './stores/identity-store';
import useQueryParameter from './composables/query-parameter.composable';
import { AdmiralService } from './services/admiral.service';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { useSessionStore } from './stores/SessionStorage';

declare global {
    interface Window {
        dataLayer: any;
        jwplayer: any;
        grecaptcha: any;
        CaptchaCallback: any;
        Pushnami: any;
        rad: any;
        trustedFormCertIdCallback: any;
        trustedFormStopRecording: any;
        gtag: any;
        admiral: any;
    }

    interface Array<T> {
        firstOrDefault<T>(): T;
        firstOrDefault<T>(predicateFunction: any): T;
    }
}

Array.prototype.firstOrDefault = function(predicateFunction = undefined) {
    if(this.length == 0) {
        return null;
    }
    if(predicateFunction == undefined || predicateFunction == null) {
        return this[0];
    }
    for(let i = 0; i < this.length; i++) {
        const item = this[i];
        if(predicateFunction.call(item)) {
            return item;
        }
    }
    return null;
};

// configure logger
const debug = useQueryParameter('rad_debug');
const logger = createLogger({
    enabled: true,
    level: process.env.NODE_ENV == 'production' && debug.value !== 'true' ? 'error' : 'debug',
    beforeHooks: [],
    afterHooks: []
});

// Events are filtered for these strings in the error message
const eventLoggingExclusions = [
    'ChunkLoadError',
    'SecurityError',
    'TypeError'
];

// configure state management
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

//  Create Service Instances
const fetchService = new FetchService();
const newslettersService = new NewslettersService(logger, fetchService);
const newsletterManageService = new NewsletterManageService(logger, fetchService);
const deviceService = new DeviceService(logger);
const gtmService = new GTMService(logger);
const utilsService = new UtilsService(logger);
const locationService = new LocationService(logger, fetchService);
const admiralService = new AdmiralService(logger);

// Load components present on the page as individual apps
components.forEach((config: ComponentDefinition) => {
    // find all elements that will use this component
    const elements = Array.from(document.getElementsByTagName(config.tag));

    // initiate each instance of those elements with the component as their own app instance
    elements.forEach((elem: Element) => {

        // make sure elem exists
        if(elem == null) {
            logger.debug(`Component ${ config.tag } is no longer accessible. Perhaps a parent element loaded instead.`);
            return;
        }

        // make sure this element isn't a child of another custom element
        let walker = elem.parentElement;
        let limit = 50;
        while(
            !!walker &&
            limit > 0 &&
            walker.tagName?.toLowerCase() !== 'body' &&
            walker.tagName?.toLowerCase().substring(0, 4) !== 'rad-'
        ) {
            walker = walker.parentElement;
            limit--;
        }
        if(!!walker && walker.tagName?.toLowerCase().substring(0, 4) === 'rad-') {
            logger.debug(`Component ${ config.tag } is nested under ${ walker.tagName } and will not be loaded again.`);
            return;
        }

        logger.debug(`Loading Component ${ config.tag }`);
        const app = createApp({});

        // register logger
        app.use(logger);

        // register pinia state management
        app.use(pinia);

        // Set Up Identity Store
        const identity = useIdentityStore();
        if(!identity.initialized) {
            const session = useSessionStore();
            session.init();
            identity.init(logger).then(() => logger.debug('Identity Store Setup Complete'));
        }

        // add services for injection
        app.provide('NewslettersService', newslettersService);
        app.provide('NewsletterManageService', newsletterManageService);
        app.provide('DeviceService', deviceService);
        app.provide('GTMService', gtmService);
        app.provide('FetchService', fetchService);
        app.provide('LocationService', locationService);
        app.provide('UtilsService', utilsService);
        app.provide('AdmiralService', admiralService);

        // register all components with app
        components.forEach((comp) => app.component(comp.tag, comp.component));

        // wrap element in container before mounting to ensure props get picked up
        if(elem.parentNode) {
            // check for custom wrapper element
            let wrapperTag = 'div';
            if (elem.hasAttribute('data-rad-wrapper-tag')) {
                wrapperTag = elem.getAttribute('data-rad-wrapper-tag') ?? wrapperTag;
                elem.removeAttribute('data-rad-wrapper-tag');
            }

            // determine if we should add in placeholder height
            let addHeight = true;
            if (elem.hasAttribute('disable-height-calc')) {
                addHeight = false;
                elem.removeAttribute('disable-height-calc');
            }

            // determine if we should add in placeholder width
            let addWidth = true;
            if (elem.hasAttribute('disable-width-calc')) {
                addWidth = false;
                elem.removeAttribute('disable-width-calc');
            }

            // create app container wrapper 
            const wrapper = document.createElement(wrapperTag);

            // create app container
            const container = document.createElement('rad-app-container');

            // insert container before target element
            elem.parentNode.insertBefore(container, elem);

            // move target element into container
            container.appendChild(elem);

            // insert wrapper before container
            container.parentNode?.insertBefore(wrapper, container);

            // move container into wrapper
            wrapper.append(container);

            // set min height/width on container wrapper to avoid collapsing on mount
            if (addHeight) {
                wrapper.style.minHeight = `${wrapper.getBoundingClientRect().height}px`;
            }

            if (addWidth) {
                wrapper.style.minWidth = `${wrapper.getBoundingClientRect().width}px`;
            }

            logger.debug(`Mounting component ${ config.tag }`);

            // only include Sentry in production and on Beliefnet (don't want it on BibleMinute)
            if(window.location.origin.includes('beliefnet.com')) {
                const html = document.querySelector('html');
                
                let contentType = '';
                if (!!html && html.hasAttribute('data-content-type')) {
                    contentType = html.getAttribute('data-content-type') ?? '';
                }
            }

            // mount to container
            app.mount(container);
        } else {
            // report to console
            logger.error(`Failed to mount component ${ config.tag }`);
        }
    });
});
