import { NewsletterSubscriptionResponse } from '../common/models/newslettermanage/newsletter-subscription-response.model';
import { VueLogger } from 'vue-logger-plugin/dist/types/logger';
import { FetchService } from './fetch.service';

const endpointBase = 'https://www.beliefnet.com/bnapi/newslettermanage';

export class NewsletterManageService {
    private logger: VueLogger;
    private fetchService: FetchService;

    constructor(logger: VueLogger, fetchService: FetchService) {
        this.logger = logger;
        this.fetchService = fetchService
    }

    /**
     * Subscribe user to newsletters
     * @param subscription subscription object to be sent to server
     */
    async subscribe(
        emailAddress: string,
        firstName: string,
        sourceDesc: string,
        rsid: string,
        newsletters: number[]
    ): Promise<NewsletterSubscriptionResponse | null> {
        this.logger.debug('Posting subscription request to server');

        try {
            const data = 
                "emailAddress=" + emailAddress + 
                "&firstName=" + firstName +
                "&sourceDesc=" + sourceDesc + 
                '&rsid=' + rsid + 
                "&newsletter=" + newsletters.join(',');

            const result = await this.fetchService.post<NewsletterSubscriptionResponse>(
                `${endpointBase}/newslettersubscribe`,
                data,
                "application/x-www-form-urlencoded"
            );

            return result.data;
        } catch(error) {
            this.logger.error('subscribe failed: ', error);
        }

        return null;
    }

    /**
     * get back newsletters based on numbers
     * @param newsletters list of newsletter ids
     */
    async confirmation(newsletters: number[]): Promise<NewsletterSubscriptionResponse | null> {
        this.logger.debug('Posting email confirmation request to server');

        try {
            const data = {
                newsletter: newsletters
            };

            const result = await this.fetchService.post<NewsletterSubscriptionResponse>(
                `${endpointBase}/newsletterconfirmation`,
                data
            );

            return result.data;
        } catch(error) {
            this.logger.error('newsletter confirmation failed: ', error);
        }

        return null;
    }

    /**
     * get back newsletters based on numbers
     * @param newsletters list of newsletter ids
     */
    async checkEmailSubscriptions(emailAddress: string): Promise<NewsletterSubscriptionResponse | null> {
        this.logger.debug('Posting check email subscriptions request to server');

        try {
            const data = {
                emailAddress
            };

            const result = await this.fetchService.post<NewsletterSubscriptionResponse>(
                `${endpointBase}/checkemailsubscriptions`,
                data
            );
            
            return result.data;
        } catch(error) {
            this.logger.error('check email subscription failed: ', error);
        }
    
        return null;
    }

    /**
     * get back newsletters based on numbers
     * @param newsletters list of newsletter ids
     */
    async updateEmailSubscription(
        emailAddress: string,
        newsletters: number[]
    ): Promise<NewsletterSubscriptionResponse | null> {
        this.logger.debug('Posting update email subscription request to server');

        try {
            const data = {
                emailAddress,
                newsletter: newsletters
            };

            const result = await this.fetchService.post<NewsletterSubscriptionResponse>(
                `${endpointBase}/updateemailsubscription`,
                data
            );
            
            return result.data;
        } catch(error) {
            this.logger.error('update email subscription failed: ', error);
        }

        return null;
    }
}
