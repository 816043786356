import { defineStore } from 'pinia';
import { useLogger, VueLogger } from 'vue-logger-plugin';
import useQueryParameter from '../composables/query-parameter.composable';
import { getEmailByVID } from '../services/bibleminute.service';
import { getEmailByRecipId, getEmailByRSID } from '../services/pathfinder.service';
import { getUserTokenByEmail, refreshUserToken } from '../services/user-token.service';
import { Lead } from '../common/models/pathfinder/lead.model';
import { createLead } from '../services/pathfinder.service';
import { useSessionStore } from './SessionStorage';

const rsidParamKey = 'rsid';
const vidParamKey = 'vid';
const recipIdParamKey = 'recipId';
const siteIdParamKey = 'siteId';
const emailParamKey = 'email';
const userEmailStorageKey = 'rad-user-email';
const userTokenStorageKey = 'rad-user-token';
const userAdvertisingToken = 'rad-uid2-token';
const userRefreshToken = 'rad-uid2-refresh-token';
const userRefreshTokenKey = 'rad-ui2d-refresh-token-key';
const userRefreshDate = 'rad-uid2-refresh-token-date';
const userIdentityExpireDate = 'rad-uid2-identity-expire-date';
const userRefreshExpireDate = 'rad-uid2-refresh-expire-date';

interface IdentityState {
    email: string,
    token: string,
    initialized: boolean,
    pathfinderLead: Lead | null,
    uid2Token: string
}
export const useIdentityStore = defineStore('identity', {
    state: () => ({
        email: '',
        token: '',
        initialized: false,
        pathfinderLead: null,
        uid2Token: ''
    } as IdentityState),
    getters: { },
    actions: {
        async init(logger: VueLogger) {
            // only need to allow this init to run once
            if (this.initialized) { return; }
            this.initialized = true;

            // try loading email and user token from local storage
            this.email = localStorage.getItem(userEmailStorageKey) ?? '';
            this.token = localStorage.getItem(userTokenStorageKey) ?? '';
            this.uid2Token = localStorage.getItem(userAdvertisingToken) ?? '';

            // if we don't have an email, try and get one
            if (this.email == '') {
                const rsid = useQueryParameter(rsidParamKey);
                const emailParam = useQueryParameter(emailParamKey);
                const vidParam = useQueryParameter(vidParamKey);
                const recipIdParam = useQueryParameter(recipIdParamKey);
                const siteIdParam = useQueryParameter(siteIdParamKey);
                
                if (emailParam.value) {
                    logger.debug('Email address found in URL');
                    this.email = emailParam.value;
                } else if (rsid.value) {
                    logger.debug('RSID found in URL. Requesting Email');
                    this.email = await getEmailByRSID(rsid.value);
                } else if (recipIdParam.value) {
                    logger.debug('RecipId found in URL. Requesting Email');
                    this.email = await getEmailByRecipId(recipIdParam.value, siteIdParam.value);
                } else if (vidParam.value) {
                    logger.debug('VID found in URL. Requesting Email');
                    this.email = await getEmailByVID(vidParam.value);
                }

                // if we got an email, let's go ahead and get a token as well and register with pathfinder
                if (this.email) {
                    await this.generateUserToken();
                    this.registerEmail(this.email);
                }
            } else if (!!this.email && (!this.token || !this.uid2Token)) {
                // if we already had an email, but no token, get a token
                await this.generateUserToken();
            }

            // if we have an email, save to storage
            if (this.email) { 
                localStorage.setItem(userEmailStorageKey, this.email); 
                this.registerEmail(this.email);
            }

            // attempt to refresh the token if we have it
            await this.refreshUserToken();

            logger.debug('Identity Store Initialized');
        },
        async registerEmail(email: string) {
            if (!!email && email !== this.email) {
                this.email = email;
                localStorage.setItem(userEmailStorageKey, this.email);
                await this.generateUserToken();
            }

            // if we haven't converted the email to a pathfinder lead, do so
            if (!!email && !this.pathfinderLead?.id) {
                const sessionStore = useSessionStore();
                // get session id  to include with lead record
                const rsid = sessionStore.rsid;

                // register with Pathfinder and get lead record
                this.pathfinderLead = await createLead({ email, rsid } as Lead);
            }
        },
        async generateUserToken() {
            if (this.email) {
                const tokenResponse = await getUserTokenByEmail(this.email);
                if (tokenResponse) {
                    localStorage.setItem(userTokenStorageKey, tokenResponse.token);
                    if (tokenResponse.uid2) {
                        localStorage.setItem(userAdvertisingToken, tokenResponse.uid2.body.advertising_token);
                        localStorage.setItem(userRefreshToken, tokenResponse.uid2.body.refresh_token);
                        localStorage.setItem(userRefreshTokenKey, tokenResponse.uid2.body.refresh_response_key);
                        localStorage.setItem(userRefreshDate, tokenResponse.uid2.body.refresh_from);
                        localStorage.setItem(userIdentityExpireDate, tokenResponse.uid2.body.identity_expires);
                        localStorage.setItem(userRefreshExpireDate, tokenResponse.uid2.body.refresh_expires);
                    }
                }
            }
        },
        async refreshUserToken() {
            const tokenRefreshDate = localStorage.getItem(userRefreshDate);
            const needsRefresh = tokenRefreshDate == null ? false : ((new Date()).getTime() > parseInt(tokenRefreshDate));

            // determine if the uid2 needs to be refreshed
            if (needsRefresh) {
                const refreshToken = localStorage.getItem(userRefreshToken) ?? '';
                const refreshKey = localStorage.getItem(userRefreshTokenKey) ?? '';
                const tokenResponse = await refreshUserToken(refreshToken, refreshKey);

                if (tokenResponse && tokenResponse.uid2) {
                    localStorage.setItem(userAdvertisingToken, tokenResponse.uid2.body.advertising_token);
                    localStorage.setItem(userRefreshToken, tokenResponse.uid2.body.refresh_token);
                    localStorage.setItem(userRefreshTokenKey, tokenResponse.uid2.body.refresh_response_key);
                    localStorage.setItem(userRefreshDate, tokenResponse.uid2.body.refresh_from);
                    localStorage.setItem(userIdentityExpireDate, tokenResponse.uid2.body.identity_expires);
                    localStorage.setItem(userRefreshExpireDate, tokenResponse.uid2.body.refresh_expires);
                }
            }
        }
    }
});