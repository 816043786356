import { UserTokenEmailRequest } from '../common/models/user-tokens/user-token-email-request.model';
import { UserTokenResponse, UserTokenResponseStatus } from '../common/models/user-tokens/user-token-response.model';
import { UserTokenRefreshRequest } from '@/common/models/user-tokens/user-token-refresh-request.model';
import { FetchService } from './fetch.service';

// get domain without subdomain (beliefnet.com instead of www.beliefnet.com)
let domain = window.location.hostname.split('.').slice(window.location.hostname.endsWith('.com.br') ? -3 : -2).join('.');

// Special Case Overrides
if (domain == 'familia.com.br') {
    // Portugese Family site will use Spanish Family domain
    domain = 'familias.com';
} else if (domain == 'notion-staging.com' || domain == 'bibleminute.co') {
    // BibleMinute will just use Beliefnet domain
    domain = 'beliefnet.com';
}
// get user token endpoint url (familia.com.br needs to pull from familias.com)
const userTokenEndpointBase = `https://cr.${domain}/public/api/v2.0/user-tokens`;

//const userTokenEndpointBase = 'http://localhost:5000/public/api/user-tokens'; // Local Test endpoint base
const userTokenEmailEndpoint = `${userTokenEndpointBase}/email`;
const userTokenRefreshUID2Endpoint = `${userTokenEndpointBase}/refresh-uid2-token`;

/**
 * Get user token by email address
 * @param email email address for which you need a user token
 * @returns user token
 */
export const getUserTokenByEmail = async (email: string): Promise<UserTokenResponse> => {
    try {
        const fetchService = new FetchService();
        const response = await fetchService.post<UserTokenResponse>(userTokenEmailEndpoint, { email } as UserTokenEmailRequest);
        if (response.ok && response.data?.result == UserTokenResponseStatus.SUCCESS) {
            return response.data;
        } else if (response.ok && response.data?.result == UserTokenResponseStatus.ERROR) {
            console.error(`Failed to get user token from api: ${response.data?.message}`);
        } else {
            console.error('Failed to get user token from api');
        }
    } catch(error) {
        console.error(`Failed to get user token from api: ${error}`);
    }
    return { } as UserTokenResponse;
}

export const refreshUserToken = async (RefreshToken: string, RefreshKey: string): Promise<UserTokenResponse> => {
    try {
        const fetchService = new FetchService();
        const response = await fetchService.post<UserTokenResponse>(userTokenRefreshUID2Endpoint, { RefreshToken, RefreshKey } as UserTokenRefreshRequest);
        if (response.ok && response.data?.result == UserTokenResponseStatus.SUCCESS) {
            return response.data;
        } else if (response.ok && response.data?.result == UserTokenResponseStatus.ERROR) {
            console.error(`Failed to get user token from api: ${response.data?.message}`);
        } else {
            console.error('Failed to get user token from api');
        }
    } catch(error) {
        console.error(`Failed to get user token from api: ${error}`);
    }
    return { } as UserTokenResponse;
}