import { VueLogger } from 'vue-logger-plugin';
import { AdmiralSubscription } from '../common/models/admiral/admiral-subscription.model';

const subscriptionCacheKey = 'rad-admiral-user-subscribed';
const loginOfferId = '6425f325808460dffff69d4a';

export class AdmiralService {
    private logger: VueLogger;

    private cacheUpdated = false;

    constructor(logger: VueLogger) {
        this.logger = logger;
    }

    /**
     * Check if user has an active subscription with Admiral
     * @returns promise of type boolean. True if subscription exists, false otherwise
     */
    public checkUserSubscribed = (): Promise<AdmiralSubscription> => {
        return new Promise<AdmiralSubscription>((resolve) => {
            const cache = localStorage.getItem(subscriptionCacheKey);
            if (cache) {
                this.logger.debug(`Subscription details found in cache: ${cache == 'false' ? 'not ' : ''}subscribed`);
                this.logger.debug('Returning cached subscription result');
                resolve(this.getSubscriptionResponse(cache == 'true'));

                // we resolve the promise but continue to make the call to
                // admiral to update our cache if we haven't done so already
                if (this.cacheUpdated) {
                    return;
                }
            }

            let responseReceived = false;
            window.admiral('after', 'measure.detected', (result: any) => {
                // only want a single response
                if (responseReceived) {
                    return;
                }
                responseReceived = true;

                this.logger.debug(
                    `Response received from Admiral regarding subscription: ${cache == 'false' ? 'not ' : ''}subscribed`
                );

                // update cache
                localStorage.setItem(subscriptionCacheKey, result?.subscribed ? 'true' : 'false');
                this.cacheUpdated = true;
                this.logger.debug('Subscription value cached');

                if (!cache) {
                    this.logger.debug('Returning subscription result');
                    resolve(this.getSubscriptionResponse(result?.subscribed || false));
                }
            });
        });
    };

    /**
     * Show admiral login screen
     */
    public showUserLogin(): void {
        window.admiral('show', 'transact.login', { offerID: loginOfferId }, () => {
            this.logger.debug('Showing the login screen');
        });
    }

    /**
     * Builds an AdmiralSubscription response based on whether they're subscribed, and what offer id they're subscribed to
     * @param isSubscribed whether or not user has a subscription
     * @returns admiral subscription response
     */
    private getSubscriptionResponse = (isSubscribed: boolean): AdmiralSubscription => {
        return {
            isSubscribed
        } as AdmiralSubscription;
    };
}
