import { VueLogger } from 'vue-logger-plugin/dist/types/logger';

export class UtilsService {
    private logger: VueLogger;
    constructor(logger: VueLogger) {
        this.logger = logger;
    }

    public includeScript(source: string, id: string, callback: () => void, nonce = '', crossOrigin = ''): void {
        if (document.getElementById(id)) {
            return;
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = id;
        script.src = source;
        script.async = true;
        script.defer = true;

        // determine if nonce is used
        if (nonce) {
            script.nonce = nonce;
        }

        if (crossOrigin) {
            script.crossOrigin = crossOrigin;
        }

        if (!!callback && typeof callback === 'function') {
            script.onload = () => {
                callback();
            };
        }

        document.head.appendChild(script);
    }
}
